
import Vue, { PropType } from 'vue';
import { answer } from '../CreateSurveyDialog.vue';
import { ShelterSetWithId } from '@/models/all';
export default Vue.extend({
  data() {
    return {
      selectedShelters: null,
    };
  },
  props: {
    advance: {
      type: Function as PropType<(answer: answer) => void>,
      required: true,
    },
    answers: {
      type: Array as PropType<answer[]>,
      required: true,
    },
  },
  computed: {
    shelters(): ShelterSetWithId[] {
      const allShelters: ShelterSetWithId[] = this.$store.get(
        'organization/shelters',
      );
      const regionMap = this.answers.find(a => a.stepID === 'zone')?.answer;
      if (!regionMap) {
        const withoutRegions = allShelters.filter(s => !s.regionMapId);
        return withoutRegions;
      }
      const withMatchingRegion = allShelters.filter(
        s => s.regionMapId === regionMap.ID,
      );
      return withMatchingRegion;
    },
  },
  methods: {
    selectAnswer() {
      this.advance({ stepID: 'shelter', answer: this.selectedShelters });
    },
  },
});
